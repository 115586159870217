import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from '@/utils/cn';

import { Menu } from './Menu';

export const SupportMenu = () => {
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(
                        'bump fixed bottom-5 right-5 z-50 flex size-[38px] cursor-pointer select-none items-center justify-center rounded-full border bg-white text-xl font-medium text-gray-400 shadow-sm outline-none',
                        'hover:text-gray-500 data-[state=open]:text-blue-500',
                    )}
                >
                    ?
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="origin-bottom-right data-[state=closed]:animate-dropdown-out data-[state=open]:animate-dropdown-in"
                    sideOffset={6}
                    align="end"
                    side="top"
                    asChild
                >
                    <div className="w-80 rounded-xl border bg-white p-2 shadow-dropdown">
                        <Menu />
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
